<template>
    <div class="page-admin-carriers">
        <div class="mt-6">
            <card title="Unmatched carriers" class="border-radius bg-transparent" body-class="p-0 pl-0 pr-0 overflow-visible">

                <v-table
                    :ref="tableRef"
                    checkable
                    :branding="false"
                    sortKey="created_at"
                    :id="tableRef"
                    :key="tableRef"
                    @row-click="rowClicked($event)"
                    @updated-total="updateTotal"
                    class="admin-table"
                    :columns="columns"
                    :endpoint="$apiUrl.carrier.unmatched"
                    query-params
                    :page-size="50">

                    <template slot="cell" slot-scope="{row, col, index}">
                        <span v-if="col.key === 'carrier_name'">
                            {{ row[col.key] }}
                        </span>

                        <span v-else-if="col.key === 'name'">
                            {{ row[col.key] }}
                        </span>

                        <span v-else-if="col.key === 'detectors'">
                            <div v-html="templateDetectors(row.additional_data)"></div>
                        </span>

                        <span v-else-if="col.key === 'unmatched'">
                            Service
                        </span>

                        <span v-else>{{ row[col.key] }}</span>

                    </template>

                </v-table>
            </card>
        </div>

        <!-- ACTION: Service -->
        <modal :show="!!activeRowService" @hide="turnOffActions()" class="unmatched-carriers__modal" size="sm">
            <h4 slot="header" v-if="activeRowService" class="">Match service</h4>

            <div slot="body" v-if="activeRowService">
                <div>
                    <p>{{ activeRowService.model.carrier }} has a service that is unmatched.</p>

                    <div class="flex mt-4">
                        <div class="w-1/2">
                            <input
                                type="checkbox"
                                id="service-variant"
                                class="form-checkbox"
                                :checked="activeRowService.step === 'variant'"
                                @change="$event.target.checked ? activeRowService.step = 'variant' : activeRowService.step = ''"
                            />
                            <label for="service-variant" class="ml-2">Add as a name variant</label><br>
                        </div>

                        <div>
                            <input
                                type="checkbox"
                                id="service-new"
                                class="form-checkbox"
                                :checked="activeRowService.step === 'new'"
                                @change="$event.target.checked ? activeRowService.step = 'new' : activeRowService.step = ''"
                            />
                            <label for="service-new" class="ml-2">Add as a new service</label><br>
                        </div>
                    </div>

                    <div class="flex flex-wrap mt-4">

                        <div class="mt-4 w-2/3" v-if="activeRowService.step === 'variant'">
                            Select existing service
                            <multi-select
                                v-model="activeRowService.existingService"
                                :branding="false"
                                :options="existingCarriersServices"
                                select-label="name"
                                select-value="id">
                            </multi-select>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeRowService" slot="footer" class="flex w-full justify-center">
                <button @click="turnOffActions()" class="btn-transparent mr-2">Cancel</button>
                <button @click="handleService()" class="btn-primary ml-2" :disabled="!activeRowService.step">Done</button>
            </div>
        </modal>
    </div>
</template>

<script>
import helper from "~/pages/admin/invoice/helper";
import Checkbox from "../../../components/Checkbox";
import ModalField from "../../../components/ModalField";
import MultiSelect from "../../../components/MultiSelect";
import axios from 'axios';

export default {

    name: 'UnmatchedCarriers',

    components: {MultiSelect, ModalField, Checkbox},

    mixins: [helper],

    metaInfo() {
        return {title: 'Carrier'}
    },

    data: () => ({
        tableRef: 'unmatched',
        id: 'unmatched',
        columns: [
            {
                title: "Carrier",
                key: "carrier_name",
                toggled: true,
                sortable: true,
                sortable_key: 'carrier_name',
                width: 'w-32'
            },
            {
                title: "Carrier Service",
                key: "name",
                toggled: true,
                sortable: true,
                sortable_key: 'name',
                width: 'w-32'
            },
            {
                title: "Detectors",
                key: "detectors",
                toggled: true,
                sortable: false,
                width: 'w-32'
            },
            {
                title: "Unmatched",
                key: "unmatched",
                toggled: true,
                sortable: false,
                width: 'w-24',
            },
        ],
        activeRowCarrier: null,
        activeRowService: null,
        existingCarriers: [],
        existingCarrierServices: []
    }),

    mounted() {
        this.fetchExistingCarriers()
    },

    computed: {
        carrierStepTwoReady() {
            if (this.activeRowCarrier.stepOne === 'variant') {
                return !!this.activeRowCarrier.existingCarrier;
            }

            return this.activeRowCarrier.stepOne === 'new';
        },
        existingCarriersServices() {
            if (!this.existingCarriers.length) {
                return [];
            }

            return this.existingCarriers.map(item => {
                return item.services;
            }).flat();
        },
    },

    methods: {
        templateDetectors(additional_data) {
            const value = [];

            for (let key in additional_data) {
                if (key.includes('legacy_addservice')) {

                    value.push(`${key} = ${additional_data[key]}`);

                    continue;
                }

                for (let _key in additional_data[key]) {
                    value.push(`${ key} ${_key} = ${additional_data[key][_key]}`);
                }
            }

            return value
                .map(item => {
                    return `
                        <div class="mb-1">${item}</div>
                    `
                })
                .join('');
        },
        async handleService() {
            const id = this.activeRowService.model.id;

            try {
                if (this.activeRowService.step != 'variant') {
                    await axios.post(`/api/carrier/detectors/${id}`, {
                        service_as_variant: false,
                    });

                    this.$snotify.success(`Successfully`);

                    this.refreshRows();

                    this.turnOffActions();

                    return
                }

                if (this.activeRowService.step === 'variant' && !this.activeRowService.existingService) {
                    this.$snotify.warning('Existing service required!');

                    return;
                }

                await axios.post(`/api/carrier/detectors/${id}`, {
                    service_as_variant: true,
                    existing_service_id: this.activeRowService.existingService.id,
                });

                this.$snotify.success(`Successfully`);

                this.refreshRows();

                this.turnOffActions();

            } catch(error) {
                this.$snotify.error(error?.response?.data?.message || 'Failed');
            }

            if (!this.activeRowService.step) {
                axios.post(`/api/carrier/detectorss/${id}`, {
                    service_as_variant: false,
                });

                this.$snotify.success(`Successfully`);

                this.refreshRows();

                return
            }
        },

        refreshRows() {
            this.$refs[this.tableRef].refreshRows();
        },

        async fetchExistingCarriers() {
            const { data } = await axios.get(this.$apiUrl.carriers.base + '?paginate=0');
            this.existingCarriers = data.data
        },

        needsFixing(model) {
            return model.carrier_id ? 'Service' : 'Carrier';
        },

        valueNeedsFixing(model) {
            return model.carrier_id ? model.service : model.carrier;
        },

        carrierNeedsFixing(model) {
            return !model.carrier_id;
        },

        turnOffActions() {
            this.activeRowCarrier = null;
            this.activeRowService = null;
        },

        rowClicked(row) {
            if (this.carrierNeedsFixing(row.data)) {
                this.activeRowService = {
                    model: row.data,
                    step: '',
                    existingService: null,
                };
            }
        },

        updateTotal(value) {
            this.$emit('total', value);
        }
    },

    watch: {
        total(value) {
            this.$emit('total', value);
        }
    }
}
</script>

<style lang="scss">
.page-admin-carriers {
    .scroller {
        max-height: calc(100vh - (300px));
    }

    .app-table__tr {
        height: 42.97px !important;
    }

    td.truncate {
        overflow: auto;
        text-overflow: initial;
        white-space: initial;
    }
}

.unmatched-carriers__modal {
    .modal__body {
        height: 600px;
    }
}
</style>
