import { render, staticRenderFns } from "./UnmatchedCarriers.vue?vue&type=template&id=20440226"
import script from "./UnmatchedCarriers.vue?vue&type=script&lang=js"
export * from "./UnmatchedCarriers.vue?vue&type=script&lang=js"
import style0 from "./UnmatchedCarriers.vue?vue&type=style&index=0&id=20440226&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports